// Same names as in variables.scss

const colors = {
    black: '#000000',
    white: '#FFFFFF',
    grey5: '#fbfbfb',
    grey10: '#F5F5F5',
    grey15: '#EDEDEF',
    grey20: '#E2E2E2',
    grey30: '#D4D4D4',
    grey32: '#b5b8b0',
    grey35: '#898989',
    grey40: '#696969',
    grey: '#5E5E5E',
    grey60: '#4C4C4C',
    grey70: '#313131',
    purple10: '#FAF5FF',
    purple15: '#f5ebff',
    purple20: '#EBD6FF',
    purple40: '#D6B4FF',
    purple: '#9933FF',
    purple60: '#822BD9',
    salmon30: '#FFE0DF',
    salmon40: '#FFBBBA',
    salmon45: '#FF9B99',
    salmon: '#FF7775',
    blue: '#0084FF',
    blue40: '#99CEFF',
    blue30: '#CCE6FF',
    green: '#30D2A9',
    green40: '#ACEDDD',
    green30: '#D6F6EE',
};

const globalColors = [
    colors.salmon30, colors.salmon40, colors.salmon, colors.purple15,
    colors.purple20, colors.purple40, colors.purple, colors.green30,
    colors.green40, colors.green,
];
const pieColors = [
    colors.salmon30, colors.salmon40, colors.salmon, colors.purple15,
    colors.purple20, colors.purple40, colors.purple, colors.green30,
    colors.green40, colors.green,
];
const lineColors = [
    colors.salmon, colors.salmon40, colors.salmon30, colors.purple,
    colors.purple40, colors.purple20, colors.purple15, colors.green,
    colors.green40, colors.green30,
];

// These are tints of the primary purple color to get maximum seperation
const themePurple = [
    '#f5ebff', '#ebd6ff', '#e0c2ff', '#d6adff', '#cc99ff', '#c285ff',
    '#b870ff', '#ad5cff', '#a347ff', colors.purple,
].reverse();

const themeSalmon = [
    '#fff1f1', '#ffe4e3', '#ffd6d6', '#ffc9c8', '#ffbbba', '#ffadac',
    '#ffa09e', '#ff9291', '#ff8583', colors.salmon,
].reverse();

export default {
    globalColors: globalColors,
    pieColors: pieColors,
    lineColors: lineColors,
    themeSalmon: themeSalmon,
    themePurple: themePurple,
    bgColor: colors.white,
    lineColor: colors.grey30,
    titleColor: colors.black,
    axisColor: colors.black,
    tooltip: colors.white,
    stackColor: colors.grey35,
    legendColor: colors.grey,
};