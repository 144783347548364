import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import getOptions from '../utils/options';

const HighChart = dynamic(() => import('Components/Chart/HighChart'), {ssr: false});

import s from './Diagram.module.scss';

const Diagram = ({
    title,
    chartData,
    description,
    options,
    type,
    inTab,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const chartOptions = getOptions(type, options);
    const chartType = _.capitalize(type);
    const showDropdown = options.dropdown === true && chartData.length > 1;

    const classes = classNames(
        [s['Diagram']],
        [s[`Diagram--${chartType}`]],
        {[s['Diagram--ShowSingle']]: showDropdown},
        {[s['Diagram--ShowAll']]: !showDropdown},
        {[s['Diagram--InTab']]: inTab},
    );

    return (
        <div className={classes}>
            <div className={s['Diagram__Header']}>
                <h4 className={s['Diagram__Title']}>{title}</h4>
                {showDropdown &&
                    <div className={s['Diagram__Dropdown']}>
                        <select 
                            className={s['Diagram__Select']}
                            onChange={(e) => setActiveIndex(Number(e.target.value))}
                            value={activeIndex}
                        >
                            {chartData.map((item, index) => 
                                <option 
                                    value={index} 
                                    key={index}
                                >{item.title}</option>
                            )}
                        </select>
                    </div>
                }
            </div>

            {showDropdown ? (
                <div className={classNames(s['Diagram__Content'], s['Diagram__Content--Single'])}>
                    <Group
                        {...chartData[activeIndex]}
                        showDropdown={showDropdown}
                        type={type}
                        options={chartOptions}
                        originalOptions={options}
                        hideTitle={true}
                    />
                </div>
            ) : (
                <div className={classNames(s['Diagram__Content'], s['Diagram__Content--All'])}>
                    {chartData.map((group, index) => (
                        <Group
                            key={index}
                            {...group}
                            type={type}
                            options={chartOptions}
                            originalOptions={options}
                        />
                    ))}
                </div>
            )}

            <div className={s['Diagram__Description']}>{description}</div>
        </div>
    );
};

Diagram.propTypes = {
    title: PropTypes.string,
    chartData: PropTypes.array,
    description: PropTypes.string,
    options: PropTypes.object,
    type: PropTypes.string,
    inTab: PropTypes.bool,
};

Diagram.defaultProps = {
    title: '',
    chartData: [],
    description: '',
    options: {},
    type: '',
    inTab: false,
};


const Group = ({
    type,
    options,
    originalOptions,
    data,
    reverse,
    title,
    hideTitle,
}) => {
    let modifier = '';
    if (type === 'pie') {
        modifier = data.length > 2 ? 'Third' : (data.length > 1 ? 'Half' : '');
    }

    const classes = classNames(
        [s['Diagram__Group']],
        {[s[`Diagram__Group--${modifier}`]]: modifier},
    );

    return (
        <div className={classes}>
            {!hideTitle && title &&
                <h5 className={s['Diagram__Group-Title']}>{title}</h5>
            }
            <div className={s['Diagram__Group-Container']}>          
                {data.map((item, index) =>
                    <Item
                        key={index}
                        {...item}
                        reverse={reverse}
                        options={Object.assign({}, options)}
                        originalOptions={originalOptions}
                    />
                )}
            </div>
        </div>
    );
};

Group.propTypes = {
    type: PropTypes.string,
    options: PropTypes.object,
    originalOptions: PropTypes.object,
    data: PropTypes.array,
    reverse: PropTypes.bool,
    title: PropTypes.string,
    hideTitle: PropTypes.bool,
};

Group.defaultProps = {
    type: '',
    options: {},
    originalOptions: {},
    data: [],
    reverse: false,
    title: '',
    hideTitle: false,
};

const Item = ({title, ...restProps}) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <div className={s['Diagram__Item']}>
            <h6 className={s['Diagram__ItemTitle']}>{title}</h6>
            {mounted ? (
                <HighChart {...restProps} />
            ) : (
                <div className={s['Diagram__Placeholder']} />
            )}
        </div>
    );
};

Item.propTypes = {
    title: PropTypes.string,
};

Item.defaultProps = {
    title: '',
};

export default Diagram;
