import _ from 'lodash';
import Highcharts from 'highcharts';
import Colors from './colors';

const undefinedIfEmpty = (val, parseFn) => {
    if(_.isEmpty(val)) {
        return undefined;
    }
    return parseFn !== null ? parseFn(val) : val;
};

const numberFormatter = (number) => {        
    return Highcharts.numberFormat(parseInt(number * 100) / 100, -1, ',', ' ');
};

const arrayColumn = (arr, column) => {
    return arr.map((item) => item[column]);
};

const getOptions = (type, settings) => {
    const defaultOptions = getDefaultOptions(type, settings);

    let typeOptions = {};
    if(type === 'column' || type === 'bar') {
        const suffix = !_.isEmpty(settings.suffix) ? settings.suffix : '';
        const prefix = !_.isEmpty(settings.prefix) ? settings.prefix : '';
        typeOptions = getBarOptions(
            settings.stacked && settings.hide_stacked_total,
            suffix,
            prefix,
            settings.stacked ? 'normal' : undefined,
        );
    } else if(type === 'pie') {
        typeOptions = getPieOptions();
    }
    return _.merge(defaultOptions, typeOptions);
};

const getPieOptions = () => {
    return {
        tooltip: {
            enabled: false,
        },
        legend: {
            labelFormatter: function() {
                return this.name + ' ' + numberFormatter(this.percentage) + '%';
            },
        },
    };
};

const getBarOptions = (hideStackedTotal, suffix, prefix, stacking) => {
    return {
        plotOptions: {
            series: {
                stacking: stacking,
            },
        },
        yAxis: {
            stackLabels: {
                align: 'right',
                textAlign: 'left',
                allowOverlap: false,
                enabled: !hideStackedTotal,
                style: {
                    color: Colors.stackColor,
                    fontWeight: 'normal',
                    fontSize: '13px',
                },
                x: 10,
                y: -5,
                verticalAlign: 'middle',
                formatter: function() {
                    return 'Summa<br>' + prefix + numberFormatter(this.total) + suffix;
                },
            },
        },
    };
};

const getDefaultOptions = (type, settings) => {
    // Define color selection, can be defined in settings, otherwise use fallback
    let globalColors = Colors.globalColors;
    // let colorByPoint = false;

    if(type === 'line') {
        globalColors = Colors.lineColors;
    }

    // This is only for a fallback, the real colors are defined when chart is rendered
    // and updated in Diagram Item component
    if(!_.isEmpty(settings.theme)) {
        const theme = settings.theme;
        const tPurple = Colors.themePurple;
        const tSalmon = Colors.themeSalmon;
        const tColors = theme === 'purple' ? tPurple : (
            theme === 'salmon' ? tSalmon : null
        );
        if(tColors !== null) {
            globalColors = tColors;
        }
    }

    if(!_.isEmpty(settings.colors)) {
        const sColors = arrayColumn(settings.colors, 'color');
        globalColors = sColors;

        // If more than one color is defined, make sure all the colors are used
        if(settings.colors.length > 1) {
            // colorByPoint = true;
        }
    }

    const height = !_.isEmpty(settings.height) ? settings.height : null;

    const pieLabelDistance = settings.show_label_outside === true ? 10 : -40;

    const credits = !_.isEmpty(settings.credits) ? {
        ...settings.credits,
        enabled: true,
        style: {
            color: Colors.legendColor,
            fontSize: '12px',
        },
    } : {enabled: false};

    return {
        chart: {
            type: type,
            style: {
                fontFamily: 'Futura LT,Arial,sans-serif',
            },
            backgroundColor: Colors.bgColor,
            width: null,
            height: height,
            spacingRight: 30,
            spacingBottom: 30,
        },
        symbols: ['circle'],
        title: {
            align:'left',
            x: 48,
            text: null,
            style: {
                color: Colors.titleColor,
            },
        },
        xAxis: {
            title: {
                text: settings.xTitle,
            },
            tickWidth: 0,
            tickInterval: undefinedIfEmpty(settings.xTickInterval, parseFloat),
            min: undefinedIfEmpty(settings.xMin, parseFloat),
            max: undefinedIfEmpty(settings.xMax, parseFloat),
            lineColor: Colors.lineColor,
            labels: {
                overflow: 'justify',
                style: {
                    color: Colors.axisColor,
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            title: {
                text: settings.yTitle,
                align: 'high',
            },
            tickInterval: undefinedIfEmpty(settings.yTickInterval, parseFloat),
            min: undefinedIfEmpty(settings.yMin, parseFloat),
            max: undefinedIfEmpty(settings.yMax, parseFloat),
            gridLineWidth: 0, 
            lineWidth: 1,
            lineColor: Colors.lineColor,
            labels: {
                overflow: 'justify',
                style: {
                    color: Colors.axisColor,
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
            },
            reversedStacks: false,
        },
        tooltip: {
            backgroundColor: Colors.tooltip,
            borderColor: Colors.tooltip,
            shape: 'rect',
            valueSuffix: !_.isEmpty(settings.suffix) ? settings.suffix : '',
            valuePrefix: !_.isEmpty(settings.prefix) ? settings.prefix : '',
            style: {
                color: Colors.axisColor,
                fontSize: '12px',
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                dataLabels: {
                    overflow: 'none',
                    crop: false,
                    style: {
                        textOutline: '',
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                },
                states: {
                    hover: {
                        brightness: 0.05,
                    },
                },
            },
            pie: {
                showInLegend: true,
                center: ['50%', '50%'],
                size: '100%',
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'allow',
                    formatter: function () {
                        if(this.percentage !== 0) {
                            return numberFormatter(this.percentage) + '%';
                        }
                    },
                    distance: pieLabelDistance,
                    style: {
                        color: Colors.titleColor,
                        textOutline: '',
                        fontSize: '18px',
                        fontWeight: 900,
                    },
                },
                states: {
                    hover: {
                        brightness: 0.05,
                        halo: {
                            size: 6,
                        },
                    },
                },
            },
            series: {
                stacking: null,
                borderWidth: 0,
            },
        },
        legend: {
            enabled: true,
            layout: 'horizontal',
            alignColumns: false,
            align: 'center',
            squareSymbol: false,
            symbolRadius: 8,
            symbolHeight: 16,
            symbolWidth: 16,
            symbolPadding: 12,
            itemMarginTop: 8,
            itemDistance: 16,
            itemStyle: {
                color: Colors.legendColor,
                fontSize: '18px',
                fontWeight: 400,
            },
            navigation: {
                enabled: false,
            },
        },
        credits: credits,
        exporting: {
            enabled: false,
        },
        colors: globalColors,
    };
};

export default getOptions;
