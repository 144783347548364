import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AppContext } from 'Layouts/App';
import { AnimateInOut } from 'Components/Animate';
import Table from 'Components/Chart/Table';
import Diagram from 'Components/Chart/Diagram';
import s from './Chart.module.scss';

const Chart = ({ data, attrs }) => {
    const { containerName } = useContext(AppContext);

    const [activeIndex, setActiveIndex] = useState(0);

    const align = _.get(attrs, 'align', '');
    const hasTabs = Boolean(data.length > 1);

    const classes = classNames(
        [s['Chart']],
        {[s[`Chart--${_.upperFirst(align)}`]]: align},
        {[s[`Chart--${_.upperFirst(containerName)}`]]: containerName},
    );

    return (
        <div className={classes}>
            {hasTabs ? (
                <div className={s['Chart__Tabbed']}>
                    <div className={s['Chart__TabList']}>
                        {data.map((item, index) => (
                            <Tab
                                key={`tab-${index}`}
                                {...item}
                                isActive={index === activeIndex}
                                onClickHandler={() => setActiveIndex(index)}
                            />
                        ))}
                    </div>

                    <Content
                        data={data}
                        active={activeIndex}
                        attrs={attrs}
                        inTab={true}
                    />
                </div>
            ) : (
                <Content
                    data={data}
                    attrs={attrs}
                />
            )}
        </div>
    );
};

Chart.propTypes = {
    data: PropTypes.array,
    attrs: PropTypes.object,
};

Chart.defaultProps = {
    data: [],
    attrs: {},
};

const Tab = ({title, isActive, onClickHandler}) => {
    const classes = classNames(
        [s['Chart__Tab']],
        {[s['Chart__Tab--Active']]: isActive},
    );

    return (
        <button
            className={classes}
            type="button"
            onClick={onClickHandler}
        >{title}</button>
    );
};

Tab.propTypes = {
    title: PropTypes.string,
    isActive: PropTypes.bool,
    onClickHandler: PropTypes.func,
};

Tab.defaultProps = {
    title: '',
    isActive: false,
    onClickHandler: () => {},
};

const Content = ({data, attrs, active, inTab}) => {
    return (
        <div className={s['Chart__Content']}>
            {data.map((item, index) => {
                const type = item.type;
                const Component = type === 'table' ? Table : Diagram;
                return (
                    <AnimateInOut
                        key={`content-${index}`}
                        isVisible={active === index}
                    >
                        <Component {...item} attrs={attrs} inTab={inTab} />
                    </AnimateInOut>
                );
            })}
        </div>
    );
};

Content.propTypes = {
    data: PropTypes.array,
    attrs: PropTypes.object,
    active: PropTypes.number,
    inTab: PropTypes.bool,
};

Content.defaultProps = {
    data: [],
    attrs: {},
    active: 0,
    inTab: false,
};

export default Chart;
