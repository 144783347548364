import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeParagraphs} from 'utils/html';
import s from './Table.module.scss';

const Table = ({ title, options, attrs, tableData }) => {
    const {thead, tbody} = tableData;

    const theme = _.get(options, 'theme', '');
    const themeName = _.isEmpty(theme) ? 'Salmon' : _.upperFirst(theme);

    const align = _.get(attrs, 'align', '');

    const classes = classNames(
        [s['Table']],
        [s[`Table--${themeName}`]],
        {[s[`Table--${_.upperFirst(align)}`]]: align},
    );

    return (
        <div className={classes}>
            <div className={s['Table__Container']}>
                {title &&
                    <h3 className={s['Table__Title']}>{title}</h3>
                }

                <div className={s['Table__Content']}>
                    <table className={s['Table__Table']}>
                        {thead.length > 0 &&
                            <Thead data={thead} />
                        }
                        {tbody.length > 0 &&
                            <Tbody data={tbody} />
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

Table.propTypes = {
    title: PropTypes.string,
    options: PropTypes.object,
    attrs: PropTypes.object,
    tableData: PropTypes.object,
};

Table.defaultProps = {
    title: '',
    options: {},
    attrs: {},
    tableData: {},
};

const Tbody = ({ data }) => (
    <tbody className={s['Table__Body']}>
        {data.map(({tr}, index) => 
            <tr 
                key={index} 
                className={classNames(s['Table__Row'], s['Table__Row--Regular'])}
            >
                {tr.length > 0 && tr.map(({td}, index) => // Kolla 
                    <td 
                        key={index} 
                        className={s['Table__Cell']}
                        dangerouslySetInnerHTML={{__html: sanitizeParagraphs(td)}}
                    />
                )}
            </tr>
        )}
    </tbody>
);

Tbody.propTypes = {
    data: PropTypes.object,
};

Tbody.defaultProps = {
    data: {},
};


const Thead = ({ data }) => (
    <thead className={s['Table__Head']}>
        <tr className={classNames(s['Table__Row'], s['Table__Row--Header'])}>
            {data.map(({th}, index) => 
                <td 
                    className={classNames(s['Table__Cell'], s['Table__Cell--Header'])} 
                    key={index}
                >
                    {th}
                </td>
            )}
        </tr>
    </thead>
);

Thead.propTypes = {
    data: PropTypes.object,
};

Thead.defaultProps = {
    data: {},
};

export default Table;
